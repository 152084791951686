import React, { useEffect, useState } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

interface Props {
  text?: string;
  onSpeechEnd?: () => void;
};

const TextToSpeech = (props: Props) => {
  const [isSpeaking, setIsSpeaking] = useState(false);

  const subscriptionKey = '44ed5e12e19b4266ba730f6aeb3f5838';
  const region = 'westeurope';

  useEffect(() => {
    if (props.text) {
      convertTextToSpeech(props.text);
    }
  }, [props.text]);


  const convertTextToSpeech = (text: string) => {
    if (!text) {
      console.log('Please enter some text to convert to speech.');
      return;
    }

    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

    setIsSpeaking(true);

    synthesizer.speakTextAsync(
      text,
      result => {
        if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
          props.onSpeechEnd && props.onSpeechEnd();
          console.log('Speech synthesis succeeded.');
        } else {
          console.error('Speech synthesis failed:', result.errorDetails);
        }
        setIsSpeaking(false);
      },
      error => {
        console.error('Error during speech synthesis:', error);
        setIsSpeaking(false);
      }
    );
  };

  return null;
};

export default TextToSpeech;
