import Sidebar, { SubLink, NavLink } from ".";

const SidebarWrapper = () => {


    const navLinks: NavLink[] = [
        { label: 'Kampai', link: '/', icon: 'company' },
        // { label: 'Terje', link: '/terje', icon: 'bell-new-notifications' },
        // { label: 'Santhia', link: '/santhia', icon: 'globe' }


    ];

    return <Sidebar navLinks={navLinks} />;
};


export default SidebarWrapper;