import React, { useState } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

interface Props {
  onTextRecognized: (text: string) => void;
}

const SpeechToText = (props: Props) => {
  
  const [isRecognizing, setIsRecognizing] = useState(false);

  const subscriptionKey = '44ed5e12e19b4266ba730f6aeb3f5838';
  const region = 'westeurope';

  const startRecognition = () => {
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

    setIsRecognizing(true);

    recognizer.recognizeOnceAsync(result => {
      if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
        props.onTextRecognized(result.text);
      } else {
        console.error('Speech recognition failed:', result);
        props.onTextRecognized('Recognition failed. Try again.');
      }
      setIsRecognizing(false);
    });
  };

  return (
    <div>
      <h3>Talk to me!</h3>
      <button className='btn btn-primary' onClick={startRecognition} disabled={isRecognizing}>
        {isRecognizing ? 'Listening...' : 'Lets hear it!'}
      </button>
      
    </div>
  );
};

export default SpeechToText;
