import { useState } from 'react';

const useOpenAI = (prompt: string) => {
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const apiKey = '7bbd51238b704be5a679fa3714d15f11';
    const endpoint = 'https://einsteinki.openai.azure.com';
    const deploymentId = 'gpt-4o-mini';

    const callOpenAI = async (message: string) => {
        setLoading(true);
        setError(null);

        const url = `${endpoint}/openai/deployments/${deploymentId}/chat/completions?api-version=2024-08-01-preview`;

        const headers = {
            'Content-Type': 'application/json',
            'api-key': apiKey,
        };

        const body = JSON.stringify({
            "messages": [
                {
                    "role": "system",
                    "content": [
                        {
                            "type": "text",
                            "text": prompt ?? "You are an AI assistant that helps people find information."
                        }
                    ]
                }
                ,
                {
                    "role": "user",
                    "content": [
                        {
                            "type": "text",
                            "text": message
                        }
                    ]
                }
            ],
            "temperature": 0.7,
            "top_p": 0.95,
            "max_tokens": 800
        });

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: body,
            });

            if (!res.ok) {
                throw new Error(`Error: ${res.statusText}`);
            }

            const data = await res.json();
            setResponse(data.choices[0].message.content.trim());
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { callOpenAI, response, loading, error };
};

export default useOpenAI;
