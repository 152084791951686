import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { IcebergProductMenu, IcebergIcon } from '@tradesolution/iceberg-ui-react';


const MainMenu = () => {
    return (
        <Navbar collapseOnSelect expand="lg" bg='info-dark' sticky='top' style={{ padding: "0.2rem 0" }} >
            <Container fluid>
                <Navbar.Brand className='text-decoration-none' href="/">
                    <IcebergIcon icon='adminportal' size="lg" />
                    <span className='ms-3'>Kampai</span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="navbarScroll" />

                <Navbar.Collapse role="navigation">
                    <Nav className='me-auto'>
                        <NavDropdown
                            title={<IcebergIcon icon='meatballs' />}
                        >
                            <IcebergProductMenu />
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse >
            </Container>
        </Navbar >
    );
}

export default MainMenu;