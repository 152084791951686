import React from 'react';
import './App.css';
import MainMenu from './components/MainMenu';
import { Row } from 'react-bootstrap';
import SidebarWrapper from './components/Sidebar/SidebarWrapper';
import { Outlet } from 'react-router';

function App() {
  return (
    <div>
            <>
                <MainMenu />
                <div className="container-fluid">
                    <Row className="flex-nowrap">
                        <SidebarWrapper />
                        <main className="col">
                            <Outlet />
                        </main>
                    </Row>
                </div>
            </>
</div>
  );
}

export default App;
