import { Col, Row } from 'react-bootstrap';
import SpeechToText from './components/SpeechToText';
import { useEffect, useState } from 'react';
import useOpenAI from '../../useOpenAI';
import TextToSpeech from './components/TextToSpeech';


interface Props {
}

interface Intent { nr: number, title: string }

const SanthiaPage  = () => {

  const intents: Intent[] = [
    { nr: 1, title: "Can you create a haiku?" },
    { nr: 2, title: "Present Team Bear" },
    { nr: 3, title: "Can you create a haiku in japanese?" },
    { nr: 4, title: "Change the title of the page" },
    { nr: 5, title: "Add some japanese mood" },
    { nr: 6, title: "Show a dance video" },
    { nr: 7, title: "Recommend top 5 Japanese foods" },
    { nr: 8, title: "How to go to Japan?" },
  ];

  const prompt = `Given the following list of intents, respond with only the number of the intent you think the user wants:
     ${intents.map((intent) => `${intent.nr}: ${intent.title}\n`)}

     If the intent is number 4, respond with the new title of the page.
  `;

  const welcomeText = 'いらっしゃいませ, Vi er Team Bear!';

  const { callOpenAI, response, loading, error } = useOpenAI(prompt);

  const openai2 = useOpenAI('');

  const [recognizedText, setRecognizedText] = useState('');

  const [pageTitle, setPageTitle] = useState('Santhia');
  const [japaneseFoods, setJapaneseFoods] = useState<{ name: string, imageUrl: string }[]>([]);
  const [showRouteMap, setShowRouteMap] = useState(false);


  const handleTextRecognized = async (text: string): Promise<void> => {
    setRecognizedText(text);
    await callOpenAI(text);
  }

  const [selectedIntent, setSelectedIntent] = useState<Intent | undefined>();

  useEffect(() => {
    console.log(response);
    if (response) {
      const intentNr = parseInt(response);
      if (isNaN(intentNr)) {
        setPageTitle(response);
        setSelectedIntent(intents.find((intent) => intent.nr === 4));
      }
      else {
        const intent = intents.find((intent) => intent.nr === intentNr);
        setSelectedIntent(intent);
      }

    }
  }, [response]);

  useEffect(() => {
    if (selectedIntent?.nr === 1 || selectedIntent?.nr === 3) {
      openai2.callOpenAI(selectedIntent.title);
    }
    if (selectedIntent?.nr === 7) {
        setJapaneseFoods([
            { name: "Sushi", imageUrl: './img/Sushi.jpg' },
            { name: "Ramen", imageUrl: './img/Ramen.jpg'  },
            { name: "Tempura", imageUrl: './img/Tempura.jpg'  },
            { name: "Takoyaki", imageUrl: './img/Takoyaki.jpg'  },
            { name: "Okonomiyaki", imageUrl: './img/Okonomiyaki.jpg'  },
        ]);
    }
    if (selectedIntent?.nr === 8) {
        // Automatically open the URL in a new tab
        window.open(
          "https://www.google.com/maps/dir/Ole+Deviks+v.,+Oslo/Tokyo,+Japan/@38.2604809,52.9286302,4z/data=!4m14!4m13!1m5!1m1!1s0x46416fbb48bc5497:0x5eb7685a727ca6d1!2m2!1d10.827969!2d59.9176199!1m5!1m1!1s0x605d1b87f02e57e7:0x2e01618b22571b89!2m2!1d139.650027!2d35.6764225!3e4?entry=ttu&g_ep=EgoyMDI0MTAyMi4wIKXMDSoASAFQAw%3D%3D",
          "_blank",
          "noopener,noreferrer"
        );
      }
  }, [selectedIntent]);

  return (
    <div>
      <Row>
        <Col>
          <h1>{pageTitle}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <SpeechToText onTextRecognized={handleTextRecognized} />
          <br />
          <p>What you said!: {recognizedText}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Intents</h3>
          <ul>
            {intents.map((intent) => (
              <li key={intent.nr} className={selectedIntent?.nr === intent.nr ? "selectedIntent" : ""}>{intent.title}</li>
            ))}
          </ul>
        </Col>
        <Col md={6}>
          {selectedIntent?.nr === 2 && (<><div>{welcomeText}<TextToSpeech text={welcomeText} /></div><div className='greatwave'></div></>)}
          {(selectedIntent?.nr === 1 || selectedIntent?.nr === 3) && (<div>{openai2.response}<TextToSpeech text={openai2.response} /></div>)}
          {selectedIntent?.nr === 5 && (<div className='redsun'><iframe width="560" height="315" src="https://www.youtube.com/embed/LGs_vGt0MY8?si=kdaFcf_my34t7cJe&amp;clip=UgkxlapwuZTh0UyX_qvVNiER7oKmbvytmHMj&amp;clipt=EJ6AAhi29QI&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"></iframe></div>)}
          {selectedIntent?.nr === 6 && (<div><iframe width="1400" height="900" src="https://www.youtube.com/embed/EHdctcgVarg?si=1N4nPNNeKczQZYwH&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"></iframe></div>)}
          {selectedIntent?.nr === 7 && (
            <div>
                <h4>Top 5 Japanese Foods:</h4>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                    {japaneseFoods.map((food, index) => (
                        <div key={index} style={{ flex: "1 1 calc(33.333% - 20px)", boxSizing: "border-box" }}>
                            <h5>{food.name}</h5>
                            <img src={food.imageUrl} alt={food.name} style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                        </div>
                    ))}
                </div>
            </div>
          )}
         {selectedIntent?.nr === 8 && (
        <div>
            <h4>Route from Oslo, Norway to Tokyo, Japan:</h4>
        </div>
        )}


        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

export default SanthiaPage;
