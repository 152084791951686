import { Col, Row } from 'react-bootstrap';
import SpeechToText from './components/SpeechToText';
import { useEffect, useState } from 'react';
import useOpenAI from '../../useOpenAI';
import TextToSpeech from './components/TextToSpeech';
interface Props {
}

interface Intent { nr: number, title: string }

const TerjePage = () => {

  const intents: Intent[] = [
    { nr: 1, title: "Can you create a haiku?" },
    { nr: 2, title: "Present Team Bear" },
    { nr: 3, title: "Can you create a haiku in japanese?" },
    { nr: 4, title: "Change the title of the page" },
    { nr: 5, title: "Add some japanese mood" },
  ];

  const prompt = `Given the following list of intents, respond with only the number of the intent you think the user wants:
     ${intents.map((intent) => `${intent.nr}: ${intent.title}\n`)}

     If the intent is number 4, respond with 4: + the new title of the page.

     If no match is found respond with a haiku about no match found
  `;

  const welcomeText = 'いらっしゃいませ, Vi er Team Bear!';

  const { callOpenAI, response, loading, error } = useOpenAI(prompt);

  const openai2 = useOpenAI('');

  const [recognizedText, setRecognizedText] = useState('');

  const [pageTitle, setPageTitle] = useState('Terje');

  const handleTextRecognized = async (text: string): Promise<void> => {
    setRecognizedText(text);
    await callOpenAI(text);
  }

  const [selectedIntent, setSelectedIntent] = useState<Intent | undefined>();

  useEffect(() => {
    console.log(response);
    if (response) {
      const intentNr = parseInt(response);
      if (isNaN(intentNr) && response.startsWith('4:')) {
        setPageTitle(response.slice(3));
        setSelectedIntent(intents.find((intent) => intent.nr === 4));
      }
      else {
        const intent = intents.find((intent) => intent.nr === intentNr);
        setSelectedIntent(intent);
      }

    }
  }, [response]);

  useEffect(() => {
    if (selectedIntent?.nr === 1 || selectedIntent?.nr === 3) {
      openai2.callOpenAI(selectedIntent.title);
    }
  }, [selectedIntent]);

  return (
    <div>
      <Row>
        <Col>
          <h1>{pageTitle}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <SpeechToText onTextRecognized={handleTextRecognized} />
          <br />
          <p>What you said!: {recognizedText}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Intents</h3>
          <ul>
            {intents.map((intent) => (
              <li key={intent.nr} className={selectedIntent?.nr === intent.nr ? "selectedIntent" : ""}>{intent.title}</li>
            ))}
          </ul>
        </Col>
        <Col md={6}>
          {!selectedIntent && response && <div>{response}<TextToSpeech text={response} /></div>}
          {selectedIntent?.nr === 2 && (<><div>{welcomeText}<TextToSpeech text={welcomeText} /></div><div className='greatwave'></div></>)}
          {(selectedIntent?.nr === 1 || selectedIntent?.nr === 3) && (<div>{openai2.response}<TextToSpeech text={openai2.response} /></div>)}
          {selectedIntent?.nr === 5 && (<div className='redsun'><iframe width="560" height="315" src="https://www.youtube.com/embed/LGs_vGt0MY8?si=kdaFcf_my34t7cJe&amp;clip=UgkxlapwuZTh0UyX_qvVNiER7oKmbvytmHMj&amp;clipt=EJ6AAhi29QI&autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"></iframe></div>)}
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

export default TerjePage;
